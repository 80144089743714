import { useCommand } from "../../../useAPI";
import * as uploadApi from "../../api";
import {
  acquiredSelfie,
  CameraError,
  useCamera,
  useSelfieSnapshot,
  useZenid,
} from "../zenidUtils";
import { Box, FeedbackBlock, unsafeLocalizedString } from "design-system";
import { useMemo, useState } from "react";
import { option } from "fp-ts";
import * as classes from "../Photo/MobileIdUpload2.treat";
import { SelfieData } from "zenid";
import { useTestEnvironment } from "../../../Common/useTestEnvironment";

export function SelfieTestPage() {
  type STATE = "Loading" | "Loaded" | "N/A" | "CameraError";
  const isTestEnv = useTestEnvironment();
  const [state, setState] = useState<STATE>(isTestEnv ? "Loading" : "N/A");
  const [cameraError, setCameraError] = useState("");

  switch (state) {
    case "CameraError":
      return (
        <FeedbackBlock
          size={"medium"}
          heading={unsafeLocalizedString("Error")}
          subheading={option.some(unsafeLocalizedString(cameraError))}
          type={"negative"}
          actions={[]}
        />
      );
    case "N/A":
      return null;
    case "Loading":
      return <InitFlow onLoaded={() => setState("Loaded")} />;
    case "Loaded":
      return (
        <ShowSelfie
          onCameraError={e => {
            setCameraError(e);
            setState("CameraError");
            console.log(e);
          }}
        />
      );
  }
}

type InitProps = {
  onLoaded: () => unknown;
};

function InitFlow(props: InitProps) {
  const startProcess = useCommand(uploadApi.startProcess);

  useZenid(
    false,
    ["selfieVideo"],
    startProcess,
    props.onLoaded,
    true,
    undefined
  );

  return (
    <Box grow hAlignContent="center" vAlignContent="center">
      <FeedbackBlock
        type="loading"
        size="large"
        heading={unsafeLocalizedString("Loading")}
        subheading={option.none}
      />
    </Box>
  );
}

type SelfieProps = {
  onCameraError: (e: CameraError) => unknown;
};

function ShowSelfie(props: SelfieProps) {
  const { feedbackText } = useCamera(
    useMemo(
      () => ({
        feature: "selfieVideo",
        cameraPreviewContainer: classes.cameraPreviewSelfie,
        onError: props.onCameraError,
      }),
      []
    )
  );

  console.log("feedbackText", feedbackText);

  useSelfieSnapshot(classes.snapshotPreview, (snapshotData: SelfieData) => {
    const data = acquiredSelfie({
      blob: snapshotData.blob,
      signature: snapshotData.signature,
    });
    console.log(data);
  });

  return <div className={classes.cameraPreviewSelfie} />;
}
