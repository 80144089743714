import "!../../../../../node_modules/extract-css-chunks-webpack-plugin/dist/loader.js!css-loader?{\"modules\":false,\"url\":false}!./MobileIdUpload2.treat.2DY77Q3.css";
export var preview = 'ElSoB';
export var blackVideoCover = '_26WQe';
export var feedbackContainer = '_36Tma';
export var feedbackContainerSelfie = '_1HYn5';
export var translucidCard = '_2IOJu';
export var feedbackConstainerSelfie = '_3oAtl';
export var divider = '_3toqr';
export var negativeFeedbackPopup = '_2x2GM';
export var negativeResultPopup = '_1z81_';
export var landscapeInfoBanner = '_1m_iW';
export var cameraPreview = '_4cBmC';
export var cameraPreviewSelfie = '_2HaaQ';
export var cameraHolder = '_2qike';
export var successIcon = '_1m0L4';
export var successMask = '_3cjlN';
export var cameraPreviewMask = '_3MhTV';
export var snapshotPreview = '_2zEm4';
export var infoButton = '_2gVRx';
export var loaderContainer = '_19roz';
export var helplineSupportContainer = '_2lr9j';